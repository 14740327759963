/* eslint-disable no-unused-vars */
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDH06lDKRtOWLwwuhI5QBFznOSEEs2noGU",
    authDomain: "somdej-c95e1.firebaseapp.com",
    projectId: "somdej-c95e1",
    storageBucket: "somdej-c95e1.appspot.com",
    messagingSenderId: "449114401574",
    appId: "1:449114401574:web:531fe0a0f55b3a30e8efe7",
    measurementId: "G-WGV6H810RV",
    databaseURL: "https://somdej-c95e1-default-rtdb.asia-southeast1.firebasedatabase.app",
};

// // Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth();