/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import logo from "./logo.svg";
import "./App.css";
import Alert from "react-bootstrap/Alert";

import {
    Button,
    Container,
    Nav,
    Navbar,
    NavDropdown,
    Offcanvas,
    Modal,
    Dropdown,
} from "react-bootstrap";
import { ethers } from "ethers";
import {
    collection,
    where,
    query,
    addDoc,
    onSnapshot,

} from "firebase/firestore";


import { db, database, auth } from "./firebase/FirebaseDB";
import { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import axios from "axios";
import simple_token_abi from "./Contracts/simple_token_abi.json";
import abi from "./Contracts/abi.json";
import styled, { css } from "styled-components";
import LoadingOverlay from "react-loading-overlay";
import Swal from "sweetalert2";
import { signInAnonymously, signOut } from "firebase/auth";

const DarkBackground = styled.div `
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999999; /* Sit on top */
  left: 0;
  top: 0px;
  margint: auto;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  ${(props) =>
    props.disappear &&
    css`
      display: block; /* show */
    `}
`;

function App() {
  const [connectWallet, setConnectWallet] = useState(false);
  const [logoutShow, setLogoutShow] = useState(false);
  const [address, setAddress] = useState(null);
  const [showBuySomdej, setShowBuySomdej] = useState(null);
  const [eth, setEth] = useState(0);
  const [price, setPrice] = useState(null);
  const [somdej, setSomdej] = useState(0);
  const [somdej2, setSomdej2] = useState(0);
  const [chainId, setChainId] = useState(0);
  const [page, setPage] = useState("Bridge");
  const [loaded, setLoaded] = useState(true);
  const [copy, setCopy] = useState("");
  const [copy2, setCopy2] = useState("");
  const [coinValue, setCoinValue] = useState("");
  const [coinPrice, setPriceValue] = useState("");

  const [dataCoin, setDataCoin] = useState(0);

  const [bnb, setBNB] = useState(null);

  const checkETHBalance = async () => {
    const userAddress = '0x8a9C7CFEE958CD29B6DE4e0aA31048f93De510f6';
    const providerUrl = 'https://mainnet.infura.io/v3/0d06f1d5592940908ad2216b78ee95e3';
    const provider = new ethers.providers.JsonRpcProvider(providerUrl);
    const balance = await provider.getBalance(userAddress);
    const balanceInTokens = ethers.utils.formatUnits(balance, 'ether'); 
    // console.log(balanceInTokens);
  
    return parseFloat(balanceInTokens) >= 0.006; 
  };
  // const handleSwapClick = () => {
  //   setPage("Swap");
  //   convertTHBtoBNB();
  //   getChainId();
  //   setCoinValue(""); // ล้างค่าช่องกรอก
  //   setPriceValue(""); // ล้างค่าช่องกรอก
  // };
  function ShowConnectWallet(props) {
    return (
      <Modal {...props} size="md" animation={true} centered>
        <Modal.Header bsPrefix="d-flex justify-content-center">
          <div>
            <h4 className="font-light text-center text-white my-5  mb-2 ms-lg-0 text-uppercase">
              {address
                ? address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 5, address.length)
                : "Connect Wallet"}
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "rgb(13, 17, 28)",
            borderRadius: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
            paddingBottom: "40px",
            // overflowY: "scroll",
            // maxHeight: "85%",
          }}
        >
          <div className="container">
            <button
              type="button"
              className="btn-connect text-white d-flex align-items-center justify-content-between w-100 text-uppercase fw-normal p-3 "
              onClick={() => {
                connectWalletHandler();
              }}
            >
              Metamask
              <img
                draggable="false"
                src="/assets/images/metamask-icon.svg"
                width={35}
                height={35}
              />
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function ShowLogoutModal(props) {
    return (
      <Modal {...props} size="md" animation={true} centered>
        <Modal.Header bsPrefix="d-flex justify-content-center">
          <div>
            <h4
              className="text-center text-white my-5  mb-2 ms-lg-0 text-uppercase font-light"
              style={{ letterSpacing: 1 }}
            >
              Wallet
            </h4>
          </div>
        </Modal.Header>
        <Modal.Body
          style={{
            background: "#010923",
            borderRadius: "20px",
            paddingRight: "20px",
            paddingLeft: "20px",
            paddingBottom: "40px",
            // overflowY: "scroll",
            // maxHeight: "85%",
          }}
        >
          <div className="container">
            <h4 className="text-white mb-3 font-light">
              {address
                ? address.substring(0, 5) +
                  "..." +
                  address.substring(address.length - 5, address.length)
                : []}
            </h4>
            <button
              type="button"
              className="font-light btn-connect d-flex  align-items-center justify-content-between w-100 text-uppercase fw-normal p-3"
              onClick={() => {
                setLogoutShow(false);
                setAddress(null);
                localStorage.removeItem("UUID");
              }}
            >
              Disconnect
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  function isMobileDevice() {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  }

  const connectWalletHandler = async () => {
    try {
      if (isMobileDevice()) {
        if (window.ethereum) {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
            // method: "eth_accounts",
          });

          if (accounts.length > 0) {
            const account = accounts[0];
            signInAnonymously(auth)
              .then((userCredential) => {
                //console.log(userCredential);
                const user = userCredential.user;
                ////console.log("====");
                ////console.log(user);
                ////console.log(account);
               

                const q = query(
                  collection(db, "Users"),
                  where("uuid", "==", account)
                );
                //console.log("====");
                onSnapshot(q, (snapshot) => {
                  //console.log(snapshot.docs.length);
                  if (snapshot.docs.length == 0) {
                    const user = collection(db, "Users");
                    addDoc(user, {
                      uuid: account,
                      created_at: Date.now(),
                    }).then(async (res) => {
                      //console.log("--------------------------------");
                      //console.log(res);
                      //console.log("--------------------------------");
                      localStorage.setItem("UUID", account);
                      setAddress(account);
                      getChainId();
                    });
                  } else {
                    snapshot.docs.forEach(async (doc) => {
                      //console.log("---------------doc-----------------");
                      //console.log(doc.data().uuid);
                      //console.log("-----------------doc---------------");
                      localStorage.setItem("UUID", account);
                      getChainId();
                      setAddress(doc.data().uuid);
                    });
                  }
                });

                setConnectWallet(false);
              })
              .catch((error) => {});
          }
        } else {
          //console.log("Install MetaMask");
        }
      } else {
        if (window.ethereum) {
        //console.log("Install MetaMask");
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });

          const message = [];

          const account = accounts[0];

          console.log("----------------");
          console.log(account);
          console.log("----------------");
          signInAnonymously(auth)
            .then((userCredential) => {
              //console.log(userCredential);
              const user = userCredential.user;
              //console.log("====");
              //console.log(user);
              const q = query(
                collection(db, "Users"),
                where("uuid", "==", account)
              );

              onSnapshot(q, (snapshot) => {
                // //console.log(snapshot.docs.length);
                if (snapshot.docs.length == 0) {
                  const user = collection(db, "Users");
                  addDoc(user, {
                    uuid: account,
                    somdej: 0,
                    created_at: Date.now(),
                  }).then(async (res) => {
                    localStorage.setItem("UUID", account);
                    setAddress(account);
                    getChainId();
                  });
                } else {
                  snapshot.docs.forEach(async (doc) => {
                    localStorage.setItem("UUID", account);
                    setAddress(doc.data().uuid);
                    getChainId();
                  });
                }
              });

              setConnectWallet(false);
              // window.ethereum
              //   .request({
              //     method: "personal_sign",
              //     params: [message, account],
              //   })
              //   .then(async (sign) => {
                
              //   })
              //   .catch((error) => {
              //     if (error.code == 4001) {
              //       console.log("Error: " + error.message);
              //       setConnectWallet(false);
              //     }
              //   });
            })
            .catch((error) => {});
        } else {
          //console.log("Install MetaMask");
        }
      }
    } catch (error) {
      // //console.log(error);
    }
  };

  const getUserBalance = async (network) => {
    const address = localStorage.getItem("UUID");
    console.log(network);
    try {
      // ETH
      if (network == 1) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        window.ethereum
          .request({
            method: "eth_getBalance",
            params: [address.toString(), "latest"],
          })
          .then((balance) => {
            //console.log(ethers.utils.formatEther(balance));
            setEth(ethers.utils.formatEther(balance));
          })
          .catch((error) => {
            //console.log(error);
          });
        const erc20 = new ethers.Contract(
          "0x5a741d624214046F2820c2b6e67082c8849EBc9f",
          simple_token_abi,
          provider
        );
        const signer = await provider.getSigner();
        const signerAddress = await signer.getAddress();
        const balance = await erc20.balanceOf(signerAddress);
        setSomdej(ethers.utils.formatEther(balance));
      } else {
        // BNB
        // const provider = new ethers.providers.Web3Provider(window.ethereum);

        window.ethereum
          .request({
            method: "eth_getBalance",
            params: [address.toString(), "latest"],
          })
          .then((balance) => {
            //console.log(ethers.utils.formatEther(balance));
            setEth(ethers.utils.formatEther(balance));
          });
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const erc20 = new ethers.Contract(
        "0xAE4cA3272792d8499575AEFacdc299F0cBdc9270",
        abi,
        provider
      );
      const signer = await provider.getSigner();
      const signerAddress = await signer.getAddress();
      const balance = await erc20.balanceOf(signerAddress);
      setSomdej2(ethers.utils.formatEther(balance));
    } catch (e) {
      console.log(e);
    }

  };


  const convertTHBtoBNB = () => {
    fetch("https://api.binance.com/api/v3/ticker/price?symbol=ETHUSDT")
      .then((response) => response.json())
      .then((data) => {
        let usdt_bnb = 3 / data.price;
        setPrice(usdt_bnb);
      });
  };

  const handler = (event) => {
    if (
      event.key === "e" ||
      event.key === "-" ||
      event.key === "+" ||
      event.key === "E"
    ) {
      event.preventDefault();
    }
  };


  const showFormBridge = ({
    values,
    errors,
    touched,
    handleBlur,
    handleSubmit,
    handleChange,
    isSubmitting,
    dirty,
    isValid,
    setFieldValue,
  }) => {
    return (
      <Form>
        <div
          className="py-4 px-3"
          style={{ borderRadius: 20, backgroundColor: "rgb(13, 17, 28)" }}
        >
          <div className="container ">
            <div className="d-flex flex-column">
              <h5 className=" text-center text-white   ms-lg-0 text-uppercase font-bold">
              หยุดการอัพเกรดเหรียญชั่วคราว <br />หากต้องการอัพเกรดให้ติดต่อ <br /> LINE : @somdej
              </h5>
            </div>
          </div>
          <img src="/assets/images/line.svg" width={"100%"} />
         
         
        </div>
      </Form>
    );
  };

  useEffect(() => {
    if (window.ethereum) {
      if (localStorage.getItem("UUID")) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        signer.getAddress().then(async (res) => {
          setAddress(res);
          getChainId();
          convertTHBtoBNB();
        });
      }
    }
    return async () => {};
  }, []);

  // const onAccountsChanged = async () => {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const accounts = await provider.listAccounts();
  //   setAddress(accounts[0] ? accounts[0] : undefined);
  //   getUserBalance();
  // };

  // const onChainChanged = async () => {
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
  //   const network = await provider.getNetwork();
  //   //console.log(network);
  //   // setChainId(network.chainId);
  //   // getUserBalance();
  // };

  if (window.ethereum) {
    window.ethereum.on("networkChanged", (networkId) => {
      //console.log(`network chain changed ${networkId}`);
      setChainId(networkId);
      getUserBalance(networkId);
    });
  }

  const getChainId = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const network = await provider.getNetwork();
    getUserBalance(network.chainId);
    setChainId(network.chainId);

  };

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join(":")
    );
  }

  return (
    <div className="App">
      <Navbar style={{ background: "#00000000" }} expand="lg">
        <Container>
          <Navbar.Brand href="#home">
            <div className="d-flex  align-items-center d-flex d-md-none">
              <img
                src="/assets/images/airbit.png"
                width={50}
                className="img-logo"
              />
              <div className="d-flex align-items-center">
                <h3
                  className="text-start fw-bold ms-3 mt-2 text-logo"
                  style={{ color: "#fff" }}
                >
                  AIRBIT
                </h3>
                <h3
                  className="text-start fw-bold ms-2 mt-2 text-logo"
                  style={{ color: "#f7b92a" }}
                >
                  PRO
                </h3>
              </div>
            </div>

            <div className="d-flex  align-items-center d-md-flex d-none">
              <img
                src="/assets/images/airbit.png"
                width={40}
                className="img-logo"
              />
              <div className="d-flex align-items-center">
                <h4
                  className="text-start fw-bold ms-3 mt-2 text-logo"
                  style={{ color: "#fff" }}
                >
                  AIRBIT
                </h4>
                <h4
                  className="text-start fw-bold ms-2 mt-2 text-logo"
                  style={{ color: "#f7b92a" }}
                >
                  PRO
                </h4>
              </div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto d-flex flex-lg-row flex-column align-items-start align-items-lg-center justify-content-start  justify-content-lg-center mt-md-2 mt-2">
              <h6
                className="font-light mt-md-2 text-white  ms-lg-5 pointer me-3  mt-3 "
                onClick={async () => {}}
              >
                <a
                  style={{ textDecorationLine: "none" }}
                  className="font-light mt-md-2 text-white   pointer  mt-3"
                  href="https://lin.ee/6UN7DIg"
                  target="_blank"
                >
                  Support
                </a>
              </h6>

              {address ? (
                <>
                  <h6
                    className="font-light mt-md-2 text-white me-5 ms-lg-5 pointer   mt-2"
                    style={{ marginRight: "41px !important" }}

                    onClick={async () => {
                      // setShowBuySomdej(true);v
                      setPage("Bridge");
                      convertTHBtoBNB();
                      getChainId();
                      setCoinValue(""); 
                      setPriceValue(""); 
                     
                    }}
                  >
                    Bridge
                  </h6>
                </>
              ) : (
                <></>
              )}

            

              {/*  */}

              {address ? (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    className="btn-dropdown text-white font-light d-flex align-items-center mt-md-0 mt-3"
                  >
                    {chainId == 1 ? (
                      <>
                        <img
                          draggable="false"
                          style={{ marginRight: 8 }}
                          src="/assets/images/ethereum.png"
                          width={25}
                          height={25}
                        />
                      </>
                    ) : (
                      <>
                        <svg
                          viewBox="0 0 16 16"
                          width="25px"
                          color="text"
                          xmlns="http://www.w3.org/2000/svg"
                          className="sc-bdvvtL wPKGk"
                          style={{ marginRight: 8 }}
                        >
                          <circle cx={8} cy={8} r={8} fill="#F0B90B" />
                          <path
                            d="M5.01656 8.00006L3.79256 9.23256L2.56006 8.00006L3.79256 6.76756L5.01656 8.00006ZM8.00006 5.01656L10.1081 7.12456L11.3406 5.89206L9.23256 3.79256L8.00006 2.56006L6.76756 3.79256L4.66806 5.89206L5.90056 7.12456L8.00006 5.01656ZM12.2076 6.76756L10.9836 8.00006L12.2161 9.23256L13.4401 8.00006L12.2076 6.76756ZM8.00006 10.9836L5.89206 8.87556L4.66806 10.1081L6.77606 12.2161L8.00006 13.4401L9.23256 12.2076L11.3406 10.0996L10.1081 8.87556L8.00006 10.9836ZM8.00006 9.23256L9.23256 8.00006L8.00006 6.76756L6.76756 8.00006L8.00006 9.23256Z"
                            fill="#FFFDFA"
                          />
                        </svg>
                      </>
                    )}
                    {address
                      ? address.substring(0, 5) +
                        "..." +
                        address.substring(address.length - 5, address.length)
                      : "Connect Wallet"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className=" font-light text-white">
                    <Dropdown.Item
                      onClick={() => {
                        signOut(auth)
                          .then((response) => {
                            setLogoutShow(false);
                            setAddress(null);
                            localStorage.removeItem("UUID");
                            setEth(0);
                            setSomdej(0);
                          })
                          .catch((error) => {});
                      }}
                    >
                      Disconnect
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  variant="success"
                  id="dropdown-basic"
                  onClick={() => {
                    if (address) {
                      setLogoutShow(true);
                    } else {
                      setConnectWallet(true);
                    }
                  }}
                  className="btn-dropdown font-light mt-3 mt-md-0"
                >
                  {address
                    ? address.substring(0, 5) +
                      "..." +
                      address.substring(address.length - 5, address.length)
                    : "Connect Wallet"}
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        className="container d-flex align-items-center justify-content-center "
        style={{ marginTop: "2rem", height: "70vh" }}
      >
        {page == "Bridge" ? (
          <>
            <Formik
              validate={(values) => {
                let errors = {};
                if (values.coin === "") errors.coin = "invalid";
                if (values.somdej === "") errors.somdej = "invalid somdej";
                return errors;
              }}
              initialValues={{
                coin: "",
                somdej: "",
              }}
             
            >
              {(props) => showFormBridge(props)}
            </Formik>
          </>
        ) : (
          <>
       
          </>
        )}

      
      </div>

      <ShowConnectWallet
        show={connectWallet}
        onHide={() => setConnectWallet(false)}
      />
      <ShowLogoutModal show={logoutShow} onHide={() => setLogoutShow(false)} />
   
      <DarkBackground disappear={!loaded}>
        <LoadingOverlay
          active={true}
          spinner={true}
          className="mt-auto h-100 w-100"
          color="#a5d8ff"
        ></LoadingOverlay>
      </DarkBackground>
    </div>
  );
}

export default App;